body {
  background-color:#70593b;
  background-image:url("/Grind-Canyon.png");
  background-size: contain;
  background-repeat: no-repeat;
  /* background-blend-mode: darken; */
  color:white;
  width:100%;
  height:100%;
  /* filter: blur(1px); */
  /* -webkit-filter: blur(1px); */
}
::-webkit-scrollbar {
  display: none;
}

.Logo {
  width: 256px;
  margin:0 auto;
  display:flex;
}

.img-wrapper {
  /* border-radius: 50%; */
  /* border:solid; */
  display: inline-flex;

}

.Blur {
  width:100%;
  height:100%;
  position: absolute;
  display:block;
    /* background-image:url("/Grind-Canyon.png"); */
}
.Coming-Soon-Container {
  margin:0;
  /* background-color: #00000075; */
  padding:0;
  margin-bottom:16px;
  padding-bottom: 8px;
}
.Wall-Image {
  margin-left:4px;
  margin-bottom:4px;
  max-width:20vw;
  max-height:20vh;
  /* max-height:calc(100vh - ; */
  display:inline-flex;
  /* display: flex; */
 align-items: center;
 justify-content: center;
   opacity:.95;
}

.Title {
  /* font-size:5vh; */
  /* margin:4px; */
}
/* .Wall-Image : hover {
  opacity:.5;
} */

.Wall-Storage {
  margin:0 auto;
  border:blue;
  /* display:none; */
}

.Subtitle {
  text-align: center;
  margin:0;
  margin-bottom:8px;
}

a:visited {
  text-decoration: none;
  color: white;
}

h1 {
  width:100%;
  /* margin:0 auto; */
  text-align: center;
  /* transform:translate(0, 50%); */
  font-family: 'Coolvetica';
  font-style: normal;
  font-weight: 400;
  font-size:72px;
  margin-top:16px;
  margin-bottom: 0;
  src:
    local('Coolvetica'),
    url('coolvetica rg.ttf') format('truetype')
}

@font-face {
  font-family: 'Coolvetica';
  font-style: normal;
  font-weight: 400;
  src:
    local('Coolvetica'),
    url('coolvetica rg.ttf') format('truetype')
}
